@import "~@epc-front/shared/styles/abstracts";

/*
  Brand
*/

$Brand-color: #d41e33;
$Brand-fontFamily: Barlow, sans-serif;

/*
  Globals
*/

$background--default: #f5f5f5;
$borderColor--default: #f5f5f5;
$color--default: #4a4a4a;

$background--error: #feecf0;
$borderColor--error: #feecf0;
$color--error: #cc0f35;

$background--info: #eff5fb;
$borderColor--info: #eff5fb;
$color--info: #296fa8;

$background--success: #effaf5;
$borderColor--success: #effaf5;
$color--success: #257953;

$background--warning: #fffaeb;
$borderColor--warning: #fffaeb;
$color--warning: #946c00;

$background--active: #287d3c;
$borderColor--active: #287d3c;
$color--active: #fff;

$background--inactive: #da1414;
$borderColor--inactive: #da1414;
$color--inactive: #fff;

$background--primary: $Brand-color;
$borderColor--primary: $Brand-color;
$color--primary: #fff;

$background--primary--active: #a2224c;
$borderColor--primary--active: #a2224c;
$color--primary--active: #fff;

$background--primary--disabled: #e3e4ec;
$borderColor--primary--disabled: #e3e4ec;
$color--primary--disabled: #686868;

$background--secondary: #fff;
$borderColor--secondary: $Brand-color;
$color--secondary: $Brand-color;

$background--secondary--active: #fff;
$borderColor--secondary--active: #a2224c;
$color--secondary--active: #a2224c;

$background--secondary--disabled: #e3e4ec;
$borderColor--secondary--disabled: #e3e4ec;
$color--secondary--disabled: #686868;

/*
  Body
*/

$Body-background: #f9f9f9;
$Body-color: #333333;
$Body-fontFamily: Barlow, sans-serif;
$Body-fontWeight: normal;
$Body-lineHeight: 1.25;

$Body-fontSize: 1.125rem;
$Small-fontSize: 1rem;
$Strong-fontSize: 1.25rem;

/*
  Content
*/

$Content-gutter: 1.5rem;
$Content-maxWidth: 90rem;

/*
  Control
*/

$Control-background: #fff;
$Control-border: 1px solid #e3e4ec;
$Control-borderRadius: null;
$Control-boxShadow: null;
$Control-color: null;
$Control-fontFamily: null;
$Control-fontSize: null;
$Control-fontWeight: null;
$Control-lineHeight: null;
$Control--disabled-background: #f5f5f5;
$Control--disabled-border: null;
$Control--disabled-color: #686868;
$Control--readOnly-background: #f5f5f5;
$Control--readOnly-border: null;
$Control--readOnly-color: #686868;
$Control--invalid-background: null;
$Control--invalid-border: 1px solid $color--error;
$Control--invalid-color: null;
$Control-onHover-background: null;
$Control-onHover-border: 1px solid #2b2b2b;
$Control-onHover-color: null;

$Control-icon-color: #2b2b2b;

$Checkbox-size: 1.5rem;

$Input-placeholder-color: #686868;

$Select-optgroup-background: #f5f5f5;
$Select-option-background: #fff;

/*
  Form
*/

$Label-fontFamily: null;
$Label-fontSize: null;
$Label-fontWeight: bold;
$Label-lineHeight: null;

$Legend-background: null;
$Legend-color: null;
$Legend-fontFamily: Barlow, sans-serif;
$Legend-fontSize: 1.5rem;
$Legend-fontWeight: bold;
$Legend-lineHeight: 1.25;

/*
  Heading
*/

$Heading-background: null;
$Heading-color: null;
$Heading-fontFamily: Barlow, sans-serif;
$Heading-fontWeight: bold;
$Heading-lineHeight: 1.25;

$Heading1-fontSize: 2rem;
$Heading2-fontSize: 1.5rem;
$Heading3-fontSize: 1.25rem;
$Heading4-fontSize: 1.25rem;
$Heading5-fontSize: 1.25rem;
$Heading6-fontSize: 1.25rem;

/*
  Icon
*/

$Icon-background: $Brand-color;
$Icon-border: null;
$Icon-color: #fff;

/*
  Components
*/

$Component-background: #fff;
$Component-border: null;
$Component-borderRadius: null;
$Component-boxShadow: null;
$Component-color: null;

// Dialog

$Dialog-background: #fff;
$Dialog-border: null;
$Dialog-borderRadius: null;
$Dialog-boxShadow: null;
$Dialog-color: null;
$Dialog-zIndex: 3;

// Field

$Field-description-color: $color--default;
$Field-description-fontFamily: null;
$Field-description-fontSize: null;
$Field-description-fontWeight: null;
$Field-description-lineHeight: null;

$Field-errorMessage-color: $color--error;
$Field-errorMessage-fontFamily: null;
$Field-errorMessage-fontSize: null;
$Field-errorMessage-fontWeight: null;
$Field-errorMessage-lineHeight: null;

$Field-indicator-color: null;
$Field-indicator-fontFamily: null;
$Field-indicator-fontSize: $Small-fontSize;
$Field-indicator-fontWeight: null;
$Field-indicator-lineHeight: null;

$Field-label-color: null;
$Field-label-fontFamily: null;
$Field-label-fontSize: null;
$Field-label-fontWeight: bold;
$Field-label-lineHeight: null;

// Form

$Form-reminder-color: null;
$Form-reminder-fontFamily: null;
$Form-reminder-fontSize: $Small-fontSize;
$Form-reminder-fontWeight: null;
$Form-reminder-lineHeight: null;

// Nav

$Nav-background: null;
$Nav-border: null;
$Nav-borderRadius: null;
$Nav-boxShadow: null;
$Nav-color: null;

$Nav-dropdown-background: #fff;
$Nav-dropdown-border: null;
$Nav-dropdown-borderRadius: null;
$Nav-dropdown-boxShadow: 0 0.5rem 0.5rem rgba(#2b2b2b, 0.1);
$Nav-dropdown-color: null;

$Nav-link-background: null;
$Nav-link-border: null;
$Nav-link-borderRadius: null;
$Nav-link-boxShadow: null;
$Nav-link-color: null;
$Nav-link-fontFamily: null;
$Nav-link-fontSize: null;
$Nav-link-fontWeight: bold;
$Nav-link-lineHeight: null;
$Nav-link-padding-x: 0;
$Nav-link-padding-y: 1.5rem;
$Nav-link-padding: $Nav-link-padding-y $Nav-link-padding-x;
$Nav-link-onHover-background: null;
$Nav-link-onHover-border: null;
$Nav-link-onHover-color: $Brand-color;

// NavBar

$NavBar-background: #fff;
$NavBar-border: null;
$NavBar-borderRadius: null;
$NavBar-boxShadow: null;
$NavBar-color: null;

$NavBar-desktop-gutter: 0.75rem 0;
$NavBar-mobile-gutter: null;

// Paper

$Paper-background: #fff;
$Paper-border: null;
$Paper-borderRadius: null;
$Paper-boxShadow: null;
$Paper-color: null;

// Separator

$Separator-color: #e3e4ec;
$Separator-style: solid;
$Separator-width: 1px;
$Separator: $Separator-width $Separator-style $Separator-color;

// TabsNav

$TabsNav-background: #fff;
$TabsNav-border: $Separator;
$TabsNav-borderRadius: null;
$TabsNav-boxShadow: null;
$TabsNav-color: null;

$TabsNav-link-background: null;
$TabsNav-link-border: null;
$TabsNav-link-borderRadius: null;
$TabsNav-link-boxShadow: null;
$TabsNav-link-color: null;
$TabsNav-link-fontFamily: null;
$TabsNav-link-fontSize: null;
$TabsNav-link-fontWeight: bold;
$TabsNav-link-lineHeight: null;
$TabsNav-link-padding-x: 0;
$TabsNav-link-padding-y: 1.5rem;
$TabsNav-link-padding: $TabsNav-link-padding-y $TabsNav-link-padding-x;
$TabsNav-link-onHover-background: null;
$TabsNav-link-onHover-border: null;
$TabsNav-link-onHover-color: $Brand-color;
