@import "../../styles/abstracts";

.AuthenticationPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-title {
    color: $Brand-color;
  }
}
