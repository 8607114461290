.Push {
  display: flex;

  &-action {
    margin-top: calc($Content-gutter * 0.5);

    > a,
    > button {
      align-items: center;
      border-bottom: 0.125rem solid currentColor;
      color: $Brand-color;
      display: inline-flex;
      font-weight: bold;
      padding-bottom: calc($Content-gutter * 0.25);
      text-decoration: none;
      text-transform: uppercase;

      &:focus,
      &:hover {
        color: inherit;
      }
    }
  }

  &-content {
    > :not(:last-child) {
      margin-bottom: 0;
    }
  }

  &-icon {
    margin-right: calc($Content-gutter * 0.5);

    > img,
    > svg {
      background: $Icon-background;
      border-radius: 1.25rem;
      color: $Icon-color;
      height: 2.5rem;
      padding: 0.5rem;
      width: 2.5rem;
    }
  }
}
