.Section {
  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-information {
    border-bottom: $Separator;

    > ul {
      display: flex;
      flex-wrap: wrap;
      gap: $Content-gutter;
      padding: 0;

      > li {
        display: flex;
        flex-grow: 1;

        @include for-desktop() {
          flex-basis: 0;
        }

        > dl {
          > dd {
            font-weight: normal;
          }

          > dt {
            font-weight: bold;
          }
        }

        > svg {
          background: $Icon-background;
          border-radius: 1.25rem;
          color: $Icon-color;
          height: 2.5rem;
          margin-right: calc($Content-gutter * 0.5);
          padding: 0.5rem;
          width: 2.5rem;
        }
      }
    }
  }

  &-title {
    padding-bottom: calc($Content-gutter * 0.5);
    position: relative;

    &::after {
      background: $Icon-background;
      bottom: 0;
      content: "";
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      width: calc($Content-gutter * 2);
    }

    > span {
      display: block;
      font-size: $Body-fontSize;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  &[data-centered="true"] & {
    &-title {
      margin: 0 auto $Content-gutter auto;
      width: fit-content;

      &::after {
        width: 100%;
      }
    }
  }
}
