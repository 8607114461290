.Stat {
  align-items: center;
  background: $background--default;
  border: 1px solid $borderColor--default;
  color: $color--default;
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  gap: calc($Content-gutter * 0.5);
  padding: calc($Content-gutter * 0.5) $Content-gutter;

  $statuses: (
    error: (
      background: $background--error,
      borderColor: $borderColor--error,
      color: $color--error,
    ),
    info: (
      background: $background--info,
      borderColor: $borderColor--info,
      color: $color--info,
    ),
    success: (
      background: $background--success,
      borderColor: $borderColor--success,
      color: $color--success,
    ),
    warning: (
      background: $background--warning,
      borderColor: $borderColor--warning,
      color: $color--warning,
    ),
  );
  @each $status, $rules in $statuses {
    &[data-status="#{$status}"] {
      background: map-get($rules, background);
      border-color: map-get($rules, borderColor);
      color: map-get($rules, color);
    }
  }
}
