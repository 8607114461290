.ThingCard {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: $Content-gutter;
  position: relative;
  width: 20rem;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-action {
    display: flex;
    margin: calc($Content-gutter * -0.25) 0 0 calc($Content-gutter * -0.25);

    > a,
    > button {
      align-items: center;
      border: 1px solid currentColor;
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      font-weight: bold;
      height: 100%;
      justify-content: center;
      margin: calc($Content-gutter * 0.25) 0 0 calc($Content-gutter * 0.25);
      min-height: 3.75rem;
      padding: calc($Content-gutter * 0.25);
      text-align: center;
      text-decoration: none;

      &:focus,
      &:hover {
        color: $Brand-color;
      }
    }
  }

  &-content {
    background: $Icon-background;
    color: $Icon-color;
    height: 100%;
    padding: $Content-gutter;

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }
  }

  &-events {
    background: #fff;
    filter: drop-shadow(0px 8px 13px rgba(0, 0, 0, 0.15));
    left: 0;
    position: absolute;
    top: calc($Content-gutter * 3);
    width: 18.5rem;

    > ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      > li {
        align-items: center;
        display: flex;
        gap: calc($Content-gutter * 0.5);
        padding: calc($Content-gutter * 0.5);

        &:not(:last-child) {
          border-bottom: $Separator;
        }

        > a {
          align-items: center;
          border: 1px solid currentColor;
          display: inline-flex;
          font-weight: bold;
          justify-content: center;
          margin-left: auto;
          padding: calc($Content-gutter * 0.25);
          text-decoration: none;
          white-space: nowrap;
          width: 7.5rem;

          &:focus,
          &:hover {
            color: $Brand-color;
          }
        }
      }
    }
  }

  &-image {
    height: 16rem;
    margin: calc($Content-gutter * -2) calc($Content-gutter * -2) 0 auto;
    position: relative;
    width: 16rem;

    > img,
    > picture > img,
    > svg {
      display: block;
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }
  }

  &-information {
    > ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        flex-grow: 1;
        text-align: center;

        > svg {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  &-title {
    font-size: $Strong-fontSize;

    > span {
      display: flex;
      font-size: $Body-fontSize;
      font-weight: normal;

      > svg {
        margin-right: calc($Content-gutter * 0.25);
      }
    }
  }
}
