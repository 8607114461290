.Dialog {
  background: $Dialog-background;
  border: $Dialog-border;
  border-radius: $Dialog-borderRadius;
  box-shadow: $Dialog-boxShadow;
  color: $Dialog-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 50%;
  max-height: calc(100% - $Content-gutter * 2);
  max-width: 90rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - $Content-gutter * 2);
  z-index: $Dialog-zIndex;

  @include for-desktop() {
    flex-direction: row;
  }

  &:focus {
    outline: none;
  }

  &-body {
    flex-grow: 1;
    overflow: auto;
    padding: $Content-gutter;

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }
  }

  &-close {
    font-size: 1.5rem;
    position: absolute;
    right: $Content-gutter;
    top: $Content-gutter;
    z-index: 1;
  }

  &-content {
    background: inherit;
    display: contents;
    width: 100%;

    @include for-desktop() {
      display: flex;
      flex-direction: column;
    }
  }

  &-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: calc($Content-gutter * 0.5);
    justify-content: center;
    padding: $Content-gutter;
  }

  &-header {
    align-items: center;
    background: inherit;
    display: flex;
    padding: $Content-gutter;
    z-index: 1;
  }

  &-icon {
    margin-right: calc($Content-gutter * 0.5);

    > img,
    > svg {
      background: $Icon-background;
      border-radius: 1.25rem;
      color: $Icon-color;
      height: 2.5rem;
      padding: 0.5rem;
      width: 2.5rem;
    }
  }

  &-image {
    height: auto;
    max-height: 6.75rem;
    min-height: 6.75rem;
    overflow: auto;
    position: relative;
    width: 100%;

    @include for-desktop() {
      max-height: 100%;
      min-height: 100%;
      width: 13.5rem;
    }

    > img,
    > picture > img {
      display: block;
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }
  }

  &-search {
    padding: $Content-gutter;
  }

  &-title {
    margin-bottom: 0;
  }

  &[data-icon="true"] & {
    &-header {
      border-bottom: $Separator;
    }
  }

  &[data-image="true"] & {
    &-header {
      @include for-desktop() {
        margin: $Content-gutter 0 0 calc($Content-gutter * -2);
      }
    }
  }

  &[data-size] {
    height: auto;
  }

  $sizes: (
    small: (
      maxWidth: 30rem,
    ),
    medium: (
      maxWidth: 60rem,
    ),
    large: (
      maxWidth: 90rem,
    ),
  );
  @each $size, $rules in $sizes {
    &[data-size="#{$size}"] {
      max-width: map-get($rules, maxWidth);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    &[data-state="closed"] {
      animation: DialogClosed 125ms ease-in;

      @keyframes DialogClosed {
        from {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }

        to {
          opacity: 0;
          transform: translate(-50%, -48%) scale(0.96);
        }
      }
    }

    &[data-state="open"] {
      animation: DialogOpen 125ms ease-out;

      @keyframes DialogOpen {
        from {
          opacity: 0;
          transform: translate(-50%, -48%) scale(0.96);
        }

        to {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}
