.Chat {
  list-style: none;
  margin: 0;
  padding: 0;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-item {
    @include for-desktop() {
      max-width: 75%;
    }

    > :not(:last-child) {
      margin-bottom: calc($Content-gutter * 0.5);
    }

    &[data-variant="received"] {
      margin-left: auto;
    }

    &-attachments {
      align-items: center;
      display: flex;
      font-size: $Small-fontSize;
      justify-content: flex-end;

      &::before {
        @include pseudo-icon(
          escape-svg(
            url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 19 20'><path clip-rule='evenodd' d='M6.13119 7.45963C7.05787 6.53295 7.98488 5.60594 8.91156 4.67926C10.4862 3.104 12.9384 2.94647 14.7095 4.30643C16.5927 5.75238 16.989 8.46357 15.5941 10.3683C15.4951 10.503 15.3889 10.6347 15.2717 10.7539C13.635 12.4155 11.9962 14.075 10.3568 15.7339C10.0868 16.0073 9.69046 16.0483 9.40866 15.8384C9.0879 15.5996 9.00326 15.1999 9.20546 14.8539C9.24173 14.7915 9.29446 14.7374 9.34619 14.685C10.947 13.0634 12.5491 11.4431 14.1492 9.82151C15.1374 8.81993 15.2388 7.14996 14.3826 6.03854C13.3975 4.75987 11.743 4.47168 10.3827 5.34765C10.2319 5.44472 10.0888 5.56227 9.96252 5.68991C8.07591 7.596 6.19097 9.50377 4.30872 11.4142C3.73505 11.9973 3.60305 12.8031 3.9584 13.4668C4.51461 14.504 5.87558 14.7182 6.7099 13.8786C8.48064 12.0944 10.2383 10.2971 12.0003 8.50421C12.2253 8.27514 12.2314 7.99704 12.0248 7.79047C11.8155 7.58189 11.528 7.59466 11.297 7.82843C9.82313 9.32039 8.35065 10.8137 6.87616 12.305C6.61787 12.5666 6.26621 12.6066 5.95854 12.4192C5.66533 12.2408 5.53803 11.8959 5.65693 11.562C5.69119 11.4653 5.75568 11.3706 5.82856 11.2963C7.31783 9.78222 8.81315 8.27346 10.3011 6.75799C10.9846 6.06172 12.0977 5.97574 12.8672 6.58602C13.7767 7.30748 13.8301 8.43099 13.2984 9.23339C13.2138 9.36103 13.1046 9.47422 12.9965 9.58438C11.2422 11.3662 9.49061 13.15 7.73029 14.9258C6.62223 16.0433 4.85822 16.1628 3.59096 15.2214C2.13695 14.1422 1.86959 12.0988 3.00956 10.6934C3.25407 10.3918 3.5352 10.1194 3.80928 9.84267C4.59086 9.05572 5.37647 8.27346 6.16074 7.48919L6.13119 7.45963Z' fill='currentColor' fill-rule='evenodd' /></svg>")
          ),
          20px,
          19px
        );
        margin-right: calc($Content-gutter * 0.25);
      }

      > ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
          &:not(:last-child) {
            &::after {
              content: ",\00a0";
            }
          }
        }
      }
    }

    &-content {
      background: #fff;
      padding: $Content-gutter;
      white-space: pre-line;
      word-break: break-all;

      > :not(:last-child) {
        margin-bottom: calc($Content-gutter * 0.5);
      }

      [data-variant="received"] > & {
        background: #ffc7cb;
      }
    }

    &-date {
      font-size: $Small-fontSize;
      text-align: right;
    }
  }
}
