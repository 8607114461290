.GreenHouseEffectChart {
  > svg {
    height: auto;
    width: 100%;
  }

  &-appendices {
    font-size: 0.625rem;
  }

  &-image {
    > g {
      $categories: (
        A: (
          color: #000,
          fill: #acd9f7,
        ),
        B: (
          color: #000,
          fill: #94b2d3,
        ),
        C: (
          color: #000,
          fill: #7b91af,
        ),
        D: (
          color: #fff,
          fill: #636f8c,
        ),
        E: (
          color: #fff,
          fill: #4d526f,
        ),
        F: (
          color: #fff,
          fill: #373651,
        ),
        G: (
          color: #fff,
          fill: #221a34,
        ),
      );
      @each $category, $rules in $categories {
        &[data-value="#{$category}"] {
          fill: map-get($rules, fill);

          > :nth-child(2) {
            fill: map-get($rules, color);
          }
        }
      }
    }
  }

  &-max,
  &-min {
    font-size: 0.75rem;
  }

  &-value {
    > g {
      > text {
        &:first-child {
          font-size: 1.875rem;
        }

        &:last-child {
          font-size: 0.5rem;
        }
      }
    }

    > line {
      fill: none;
      stroke: currentColor;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }
  }
}
