.GalleryCard {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: $Content-gutter;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-action {
    display: flex;
    margin-top: auto;

    > a,
    > button {
      width: 100%;
    }
  }

  &-image {
    height: 16rem;
    margin: 0 auto;
    position: relative;
    width: 100%;

    > img,
    > picture > img {
      display: block;
      height: 100%;
      object-fit: contain;
      position: absolute;
      width: 100%;
    }
  }
}
