.Stepper {
  display: flex;
  list-style: none;
  margin: calc($Content-gutter * -1) 0 0 calc($Content-gutter * -1);
  padding: 0;

  &-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: $Content-gutter 0 0 $Content-gutter;
    position: relative;

    > :not(:last-child) {
      margin-bottom: calc($Content-gutter * 0.25);
    }

    &-indicator {
      align-items: center;
      background: #fff;
      border: $Separator;
      border-radius: 1rem;
      display: flex;
      flex-shrink: 0;
      height: 2rem;
      justify-content: center;
      width: 2rem;

      &::before {
        background: $Separator-color;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        top: 1rem;
        width: calc(100% + $Content-gutter);
        z-index: -1;

        :first-child > & {
          left: 50%;
        }

        :last-child > & {
          width: 50%;
        }
      }
    }

    &-label {
      display: block;
      font-size: $Small-fontSize;
      height: 0;
      overflow: hidden;
      text-transform: uppercase;
      width: 0;

      @include for-desktop() {
        height: auto;
        overflow: visible;
        width: auto;
      }
    }

    &[aria-current="true"] & {
      &-indicator {
        background: $Separator-color;
        color: #fff;

        &::after {
          @include pseudo-icon(
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 12 12'><circle cx='6' cy='6' fill='currentColor' r='4' /></svg>")
            ),
            12px,
            12px
          );
        }
      }
    }

    $statuses: (
      error: (
        background: $color--error,
        color: #fff,
        icon: (
          height: 13px,
          image:
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 14 13'><path clip-rule='evenodd' d='M6.87188 4.93152L11.4879 0.315492C11.9086 -0.105164 12.5906 -0.105164 13.0112 0.315492C13.4319 0.736148 13.4319 1.41817 13.0112 1.83882L8.39521 6.45485L13.0112 11.0709C13.4319 11.4915 13.4319 12.1736 13.0112 12.5942C12.5906 13.0149 11.9086 13.0149 11.4879 12.5942L6.87188 7.97818L2.25606 12.594C1.8354 13.0147 1.15338 13.0147 0.732729 12.594C0.312072 12.1733 0.312072 11.4913 0.732729 11.0707L5.34855 6.45485L0.732729 1.83903C0.312072 1.41838 0.312072 0.736358 0.732729 0.315701C1.15338 -0.104955 1.8354 -0.104955 2.25606 0.315701L6.87188 4.93152Z' fill='currentColor' fill-rule='evenodd' /></svg>")
            ),
          width: 14px,
        ),
      ),
      info: (
        background: $color--info,
        color: #fff,
        icon: (
          height: 12px,
          image:
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 12 12'><circle cx='6' cy='6' fill='currentColor' r='4' /></svg>")
            ),
          width: 12px,
        ),
      ),
      success: (
        background: $color--success,
        color: #fff,
        icon: (
          height: 10px,
          image:
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 22 16'><path d='M3.10281 7.15538C2.59312 6.64623 1.76719 6.64667 1.25804 7.15636C0.748892 7.66604 0.749328 8.49197 1.25902 9.00113L7.98406 15.7191L21.0022 2.7126C21.5119 2.20341 21.5122 1.37748 21.003 0.86783C20.4939 0.358182 19.6679 0.357811 19.1583 0.867002L7.98391 12.0313L3.10281 7.15538Z' fill='currentColor' /></svg>")
            ),
          width: 14px,
        ),
      ),
      warning: (
        background: $color--warning,
        color: #fff,
        icon: (
          height: 10px,
          image:
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 22 16'><path d='M3.10281 7.15538C2.59312 6.64623 1.76719 6.64667 1.25804 7.15636C0.748892 7.66604 0.749328 8.49197 1.25902 9.00113L7.98406 15.7191L21.0022 2.7126C21.5119 2.20341 21.5122 1.37748 21.003 0.86783C20.4939 0.358182 19.6679 0.357811 19.1583 0.867002L7.98391 12.0313L3.10281 7.15538Z' fill='currentColor' /></svg>")
            ),
          width: 14px,
        ),
      ),
    );
    @each $status, $rules in $statuses {
      &[data-status="#{$status}"] & {
        &-indicator {
          background: map-get($rules, background);
          color: map-get($rules, color);

          &::after {
            $icon: map-get($rules, icon);
            @include pseudo-icon(
              map-get($icon, image),
              map-get($icon, width),
              map-get($icon, height)
            );
          }
        }
      }
    }
  }
}
