.Accordion {
  &-body {
    border-top: $Separator;
    overflow: hidden;

    > :not(:last-child) {
      margin-bottom: $Content-gutter;
    }

    @media (prefers-reduced-motion: no-preference) {
      &[data-state="closed"] {
        animation: CollapsibleClosed 250ms ease-in;

        @keyframes CollapsibleClosed {
          from {
            height: var(--radix-collapsible-content-height);
          }

          to {
            height: 0;
          }
        }
      }

      &[data-state="open"] {
        animation: CollapsibleOpen 250ms ease-out;

        @keyframes CollapsibleOpen {
          from {
            height: 0;
          }

          to {
            height: var(--radix-collapsible-content-height);
          }
        }
      }
    }
  }

  &-header {
    align-items: center;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: calc($Content-gutter * 0.5);

    > div {
      align-items: center;
      display: flex;
      padding: calc($Content-gutter * 0.5);

      &:last-child {
        margin-left: auto;
      }
    }
  }

  &-icon {
    margin-right: calc($Content-gutter * 0.5);
  }

  &-title {
    flex-grow: 1;
    margin-bottom: 0;
  }

  &-trigger {
    align-items: center;
    background: $background--primary;
    border: 1px solid $borderColor--primary;
    color: $color--primary;
    display: inline-flex;
    justify-content: center;
    margin-left: calc($Content-gutter * 0.5);
    min-height: 3.25rem;
    min-width: 3.25rem;

    &::before {
      @include pseudo-icon(
        escape-svg(
          url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 12 7'><path d='M1 1L6 6L11 1' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' /></svg>")
        ),
        12px,
        7px
      );
    }

    &[aria-expanded="true"] {
      transform: rotate(-180deg);
    }

    &:not(:disabled) {
      &:focus,
      &:hover {
        background: $background--primary--active;
        border-color: $borderColor--primary--active;
        color: $color--primary--active;
      }
    }
  }
}
