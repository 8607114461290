@import "@epc-front/shared/components/ThingCard";

.ThingCard {
  $values: (
    BOOKING: (
      background: #00837d,
      color: #fff,
    ),
    COMMERCIAL_LEASE: (
      background: #01802b,
      color: #fff,
    ),
    COMMERCIAL_LEASE_EDENEA: (
      background: #01802b,
      color: #fff,
    ),
    COMMERCIAL_LEASE_STUDEA: (
      background: #01802b,
      color: #fff,
    ),
    MANAGEMENT: (
      background: #01802b,
      color: #fff,
    ),
    MULTI: (
      background: #cc0066,
      color: #fff,
    ),
    NEW: (
      background: #0064dc,
      color: #fff,
    ),
    RENT: (
      background: #cc3300,
      color: #fff,
    ),
    RENT_EDENEA: (
      background: #cc3300,
      color: #fff,
    ),
    RENT_STUDEA: (
      background: #cc3300,
      color: #fff,
    ),
    TRUSTEE: (
      background: #7d559b,
      color: #fff,
    ),
    TRUSTEE_SYNDICATE: (
      background: #7d559b,
      color: #fff,
    ),
  );
  @each $value, $rules in $values {
    &[data-context="Lot"][data-value="#{$value}"] & {
      &-content {
        background: map-get($rules, background);
        color: map-get($rules, color);
      }
    }
  }
}
