.TabsNav {
  background: $TabsNav-background;
  border-bottom: $TabsNav-border;
  border-radius: $TabsNav-borderRadius;
  box-shadow: $TabsNav-boxShadow;
  color: $TabsNav-color;
  width: 100%;

  > div {
    margin: 0 auto;
    max-width: $Content-maxWidth;
    padding: $Content-gutter;
    width: 100%;

    @include for-desktop() {
      padding: 0 $Content-gutter;
    }
  }

  &-menu {
    display: none;

    @include for-desktop() {
      display: block;
    }

    > div {
      > ul {
        display: flex;
        list-style: none;
        margin: 0 0 0 calc($Content-gutter * -1);
        padding: 0;

        > li {
          flex-grow: 1;
          margin: 0 0 0 $Content-gutter;

          > a,
          > button {
            align-items: center;
            background: $TabsNav-link-background;
            border: $TabsNav-link-border;
            border-radius: $TabsNav-link-borderRadius;
            box-shadow: $TabsNav-link-boxShadow;
            color: $TabsNav-link-color;
            display: flex;
            font-family: $TabsNav-link-fontFamily;
            font-size: $TabsNav-link-fontSize;
            font-weight: $TabsNav-link-fontWeight;
            height: 100%;
            justify-content: center;
            line-height: $TabsNav-link-lineHeight;
            padding: $TabsNav-link-padding;
            position: relative;
            text-align: center;
            text-decoration: none;
            width: 100%;

            &::after {
              background: $TabsNav-link-onHover-color;
              bottom: 0;
              content: "";
              display: block;
              height: 3px;
              left: 0;
              position: absolute;
              transition: width 0.25s ease-in-out;
              width: 0;
            }

            &[aria-current],
            &:focus,
            &:hover {
              background: $TabsNav-link-onHover-background;
              border: $TabsNav-link-onHover-border;
              color: $TabsNav-link-onHover-color;

              &::after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &-select {
    @include for-desktop() {
      display: none;
    }
  }
}
