.FieldGroup {
  position: relative;

  > :not(:last-child) {
    margin-bottom: calc($Content-gutter * 0.5);
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    margin: calc($Content-gutter * -1) 0 0 calc($Content-gutter * -1);
    width: fit-content;

    > button,
    > div {
      flex-grow: 1;
      margin: $Content-gutter 0 0 $Content-gutter;
    }

    [data-column-count] > & {
      width: auto;

      > button,
      > div {
        max-width: calc(100% - $Content-gutter);
        width: 100%;
      }
    }

    @include for-desktop() {
      $columnCounts: 2, 3, 4;
      @each $columnCount in $columnCounts {
        [data-column-count="#{$columnCount}"] > & {
          > button,
          > div {
            max-width: calc(1 / $columnCount * 100% - $Content-gutter);
          }
        }
      }
    }
  }

  &-description {
    color: $Field-description-color;
    font-family: $Field-description-fontFamily;
    font-size: $Field-description-fontSize;
    font-weight: $Field-description-fontWeight;
    line-height: $Field-description-lineHeight;
  }

  &-errorMessage {
    color: $Field-errorMessage-color;
    font-family: $Field-errorMessage-fontFamily;
    font-size: $Field-errorMessage-fontSize;
    font-weight: $Field-errorMessage-fontWeight;
    line-height: $Field-errorMessage-lineHeight;
  }

  &-label {
    color: $Field-label-color;
    display: block;
    font-family: $Field-label-fontFamily;
    font-size: $Field-label-fontSize;
    font-weight: $Field-label-fontWeight;
    line-height: $Field-label-lineHeight;
    white-space: pre-line;
  }
}
