.DpeChart {
  > svg {
    height: auto;
    width: 100%;
  }

  &-image {
    > g {
      > g {
        > :last-child {
          fill: currentColor;
        }
      }

      $categories: (
        A: (
          color: #000,
          fill: #009b6d,
        ),
        B: (
          color: #000,
          fill: #52b053,
        ),
        C: (
          color: #000,
          fill: #79bd76,
        ),
        D: (
          color: #000,
          fill: #fae900,
        ),
        E: (
          color: #000,
          fill: #eeb312,
        ),
        F: (
          color: #000,
          fill: #eb8134,
        ),
        G: (
          color: #fff,
          fill: #d72220,
        ),
      );
      @each $category, $rules in $categories {
        &[data-value="#{$category}"] {
          fill: map-get($rules, fill);

          > path {
            fill: map-get($rules, color);
          }
        }
      }
    }
  }

  &-max,
  &-min {
    fill: currentColor;
    font-size: 0.75rem;
  }

  &-value {
    > g {
      > text {
        &:first-child {
          font-size: 1.875rem;
        }

        &:last-child {
          font-size: 0.5rem;
        }
      }
    }

    > line {
      fill: none;
      stroke: currentColor;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }
  }
}
