@import "../styles/abstracts";

.MaintenancePage {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;

  &-content {
    background: #48accf;
    color: #fff;
    flex-grow: 1;
    padding: $Content-gutter;
    width: 100%;
  }

  &-image {
    margin-bottom: -1.5rem;
    z-index: 1;

    > img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      width: auto;
    }
  }

  &-logo {
    flex-shrink: 0;
    margin: 1.5rem auto;
  }
}
