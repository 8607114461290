@import "../../styles/abstracts";

.AppendicesNav {
  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: calc($Content-gutter * 0.5);
    justify-content: center;

    > li {
      > a,
      > button {
        color: #666;
        font-size: $Small-fontSize;

        &:focus,
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
