.ContactCard {
  background: #5fdbdb;
  color: #000;
  padding: $Content-gutter;

  > :not(:last-child) {
    margin-bottom: calc($Content-gutter * 0.5);
  }

  &-item {
    &-label {
      font-weight: bold;
    }

    &-value {
      align-items: center;
      display: flex;
      font-weight: normal;

      &::before {
        margin-right: calc($Content-gutter * 0.25);
      }

      &[data-role="email"] {
        &::before {
          @include pseudo-icon(
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 32 27'><path clip-rule='evenodd' d='M29.0909 6.3745V2.90909H2.90909V6.37381L16 12.9199L29.0909 6.3745ZM29.0909 9.62696L16 16.1724L2.90909 9.62634V23.2727H29.0909V9.62696ZM2.90909 0H29.0909C30.6976 0 32 1.30244 32 2.90909V23.2727C32 24.8794 30.6976 26.1818 29.0909 26.1818H2.90909C1.30244 26.1818 0 24.8794 0 23.2727V2.90909C0 1.30244 1.30244 0 2.90909 0Z' fill='currentColor' fill-rule='evenodd' /></svg>")
            ),
            16px,
            16px
          );
        }
      }

      &[data-role="phoneNumber"] {
        &::before {
          @include pseudo-icon(
            escape-svg(
              url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' fill='none' focusable='false' stroke='none' viewBox='0 0 24 24'><path clip-rule='evenodd' d='M23.5551 21.5807C23.6812 20.6609 23.8359 19.5355 23.9818 18.4721C24.1104 17.5356 23.545 16.6436 22.6442 16.3604L17.8541 14.8547C17.3917 14.7088 16.8878 14.8038 16.5098 15.1075C15.7989 15.6791 15.0452 16.2822 14.3725 16.8322C14.2359 16.9436 14.1334 16.9562 13.977 16.8829C12.7488 16.3066 11.6582 15.5239 10.6485 14.6298C10.4681 14.4704 10.2902 14.3084 10.1141 14.1448C9.93689 13.9823 9.76124 13.8179 9.58764 13.651C8.61551 12.7161 7.74767 11.6916 7.07409 10.513C6.9884 10.3632 6.99267 10.2596 7.0927 10.1146C7.58568 9.39851 8.12509 8.59788 8.6365 7.84217C8.90843 7.44049 8.9622 6.93014 8.77938 6.48078L6.89145 1.82406C6.53639 0.948243 5.60216 0.456686 4.68021 0.661032C3.63314 0.892885 2.52496 1.13824 1.61923 1.33865C0.56158 1.57256 -0.126338 2.79265 0.0194395 3.867C0.231277 5.26735 0.580016 6.63472 1.05934 7.97014C1.5658 9.38006 2.1991 10.7285 2.96963 12.0106C4.15463 13.9821 5.56904 15.7455 7.19375 17.3154C8.8888 18.8088 10.7593 20.0769 12.8188 21.0992C14.1579 21.764 15.552 22.2865 16.9972 22.677C18.3657 23.0471 19.7555 23.2841 21.1674 23.3818C22.2489 23.4403 23.408 22.6548 23.5551 21.5807Z' fill='currentColor' fill-rule='evenodd' /></svg>")
            ),
            16px,
            16px
          );
        }
      }
    }
  }

  &-title {
    border: 1px solid currentColor;
    padding: calc($Content-gutter * 0.25);
    width: fit-content;
  }
}
