@import "../../styles/abstracts";

.CompanyNav {
  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: calc($Content-gutter * 0.5);
    justify-content: center;
    text-align: center;

    @include for-desktop() {
      justify-content: start;
      text-align: left;
    }

    > li {
      > a {
        color: #666;
        font-weight: bold;
        padding: 0.875rem 0;
        text-decoration: none;

        &::after {
          background: currentColor;
          content: "";
          display: block;
          height: 0.125rem;
          margin-top: 0.25rem;
          transition: width 0.25s ease-in-out;
          width: 0;
        }

        &[aria-current="true"],
        &:focus,
        &:hover {
          color: inherit;

          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
