@import "../../styles/abstracts";

.MainPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &-about {
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        align-items: center;
        display: flex;

        &:not(:last-child) {
          margin-bottom: calc($Content-gutter * 0.25);
        }

        > svg {
          margin-right: calc($Content-gutter * 0.25);
        }
      }
    }
  }

  &-header {
    background: var(--theme-background, #fff);
    color: var(--theme-color, inherit);
    height: auto;

    > div {
      padding-top: 0;

      > :not(:last-child) {
        margin-bottom: calc($Content-gutter * 0.25);
      }
    }
  }

  &-title {
    align-items: center;
    display: flex;
    margin-bottom: 0;

    > svg {
      color: var(--theme-color, $Brand-color);
      margin-right: calc($Content-gutter * 0.5);
    }
  }
}
