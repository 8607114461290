.Form {
  &-actions {
    display: flex;
    justify-content: center;
  }

  &-legend {
    align-items: center;
    display: flex;
    width: 100%;

    > svg {
      background: $Icon-background;
      border-radius: 1.25rem;
      color: $Icon-color;
      height: 2.5rem;
      margin-right: calc($Content-gutter * 0.5);
      padding: 0.5rem;
      width: 2.5rem;
    }

    &[data-icon="true"] {
      border-bottom: $Separator;
      padding-bottom: $Content-gutter;
    }
  }

  &-reminder {
    color: $Form-reminder-color;
    font-family: $Form-reminder-fontFamily;
    font-size: $Form-reminder-fontSize;
    font-weight: $Form-reminder-fontWeight;
    line-height: $Form-reminder-lineHeight;
  }
}
