.FieldList {
  border-bottom: $Separator;
  list-style: none;
  margin: 0;
  padding: 0 0 $Content-gutter 0;

  > :not(:last-child) {
    margin-bottom: $Content-gutter;
  }

  &-item {
    background: #e3e4ec;
    padding: $Content-gutter;
  }
}
