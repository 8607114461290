@import "../../styles/abstracts";

.AppStoresPush {
  display: flex;
  flex-direction: column;
  gap: calc($Content-gutter * 0.5);
  text-align: center;

  &-nav {
    > ul {
      display: flex;
      flex-wrap: wrap;
      gap: calc($Content-gutter * 0.5);
      justify-content: center;

      > li {
        > a {
          &:focus,
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }

  &-text {
    > strong {
      font-size: $Strong-fontSize;
    }
  }
}
